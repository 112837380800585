import { FileData } from "client/types/FileData";
import { HandleFile } from "client/types/HandleFile";
import { UploadOptions } from "client/types/UploadOptions";
import { getFileData } from "client/utils/getFileData";
import { useEffect, useState } from "react";
import { useValidateFiles } from "../upload/useValidateFiles";
import { useDebounce } from "./useDebounce";

export const useDragUpload = (
    element: HTMLElement | null,
    handleFile: HandleFile,
    options: UploadOptions,
) => {
    const [isDragging, setIsDragging] = useState(false);
    const uploadDebounce = useDebounce(300);
    const setIsDraggingDebounce = useDebounce(300);

    const dragListener = (e: DragEvent) => {
        e.preventDefault();
    };

    const dragEnterListener = (e: DragEvent) => {
        setIsDragging(true);
        e.preventDefault();
    };

    const dragStartListener = (e: DragEvent) => {
        e.preventDefault();
    };

    const dragLeaveListener = (e: DragEvent) => {
        setIsDraggingDebounce(() => setIsDragging(false));
        e.preventDefault();
    };

    const dragOverListener = (e: DragEvent) => {
        setIsDraggingDebounce(() => setIsDragging(true));
        e.preventDefault();
    };

    const dragEndListener = (e: DragEvent) => {
        e.preventDefault();
    };

    const dropListener = (e: DragEvent) => {
        setIsDraggingDebounce(() => setIsDragging(false));
        e.preventDefault();

        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items[0]) {
            const files = e.dataTransfer.items;

            if (options.allowMultiple) {
                const fileDatas: FileData[] = [];

                for (let i = 0; i < files.length; i++) {
                    const file = files[i]?.getAsFile();
                    if (!file) continue;

                    fileDatas.push(getFileData(file));
                }

                executeHandleFile(fileDatas);
            } else {
                const file = e.dataTransfer.items[0].getAsFile();
                if (file) {
                    executeHandleFile([getFileData(file)]);
                }
            }
        }
    };

    const { validateFiles } = useValidateFiles(options.supportedExtensions);
    const executeHandleFile = (fileDatas: FileData[]) => {
        uploadDebounce(() => handleFile(validateFiles(fileDatas), "device", {}));
    };

    const setupDragUpload = () => {
        if (!element) return;
        element.addEventListener("drag", dropListener);
        element.addEventListener("dragenter", dragEnterListener);
        element.addEventListener("dragstart", dragStartListener);
        element.addEventListener("dragleave", dragLeaveListener);
        element.addEventListener("dragover", dragOverListener);
        element.addEventListener("dragend", dragEndListener);
        element.addEventListener("drop", dropListener);
    };

    useEffect(() => {
        setupDragUpload();
        return () => {
            if (!element) return;
            element.removeEventListener("drag", dragListener);
            element.removeEventListener("dragenter", dragEnterListener);
            element.removeEventListener("dragstart", dragStartListener);
            element.removeEventListener("dragleave", dragLeaveListener);
            element.removeEventListener("dragover", dragOverListener);
            element.removeEventListener("dragend", dragEndListener);
            element.removeEventListener("drop", dropListener);
        };
    }, [element]);

    return { isDragging };
};
