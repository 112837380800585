import { FileData } from "client/types/FileData";
import { getImageExtension } from "client/utils/getImageExtension";
import { Task } from "@freeconvert/freeconvert-node/dist/types";
import Resumable from "resumablejs";
import { Size } from "store/types/previewImage";
import { calculateSpeed } from "./utils/calculateSpeed";
import { TaskService } from "../Task/TaskService";
import { gtag } from "client/utils/adsense/gtag";
import axiosInstance from "client/lib/axiosInterceptor";
import { MAX_RETRY_COUNT } from "client/config/configs";

export interface DeviceImportOptions {
    onProgress: (task: Task, progress: number, speed: Size, metaData: any) => void;
    onComplete: (task: Task, metaData: any) => void;
    onFailure: (task: Task, metaData: any) => void;
    onDisconnect?: (task: Task, metaData: any) => void;
    onReconnect?: (task: Task, metaData: any) => void;
    onOfflineLimitReach?: (task: Task, metaData: any) => void;
}

const TEN_SECONDS = 10 * 1000;

export class DeviceImport {
    public task: Task;
    private completedTask: Task | null = null;
    private file: FileData;
    private options: DeviceImportOptions;
    private r: Resumable | null = null;
    private metaData: any;
    private hasCompleted = false;
    private isAcknowledgementComplete = false;
    private taskCompleteTimeout: NodeJS.Timeout | null = null;
    private joinTryCount = 0;
    private confirmTryCount = 0;
    private state: "resumable" | "join" | "confirm" = "resumable";

    constructor(task: Task, file: FileData, options: DeviceImportOptions, metaData: any) {
        this.task = task;
        this.file = file;
        this.options = options;
        this.metaData = metaData;
    }

    public async startImport() {
        const taskService = new TaskService(this.task, {
            handleDisconnect: this.handleDisconnect,
            handleReconnect: this.handleReconnect,
            handleTaskComplete: (task) => {
                this.handleTaskComplete(task);
            },
            handleTaskFail: this.handleTaskFail,
            onOfflineLimitReach: this.handleOfflineLimit,
        });
        await taskService.appendTask(false);
        this.init();
    }

    private handleTaskComplete = (task: Task) => {
        this.completedTask = task;
        if (this.isAcknowledgementComplete) {
            this.options.onComplete(task, this.metaData);
            this.hasCompleted = true;

            if (this.taskCompleteTimeout) clearTimeout(this.taskCompleteTimeout);
        }
    };

    private handleOfflineLimit = (task: Task) => {
        this.options.onOfflineLimitReach && this.options.onOfflineLimitReach(task, this.metaData);
    };

    private handleTaskFail = (task: Task) => {
        this.r?.pause();
        this.r?.cancel();
        this.options.onFailure(task, this.metaData);
        this.cancel();
    };

    private handleReconnect = () => {
        this.r && this.r.upload();
        this.options.onReconnect && this.options.onReconnect(this.task, this.metaData);
    };

    private handleDisconnect = () => {
        this.r && this.r.pause();
        this.options.onDisconnect && this.options.onDisconnect(this.task, this.metaData);
    };

    private init() {
        this.r && this.r.pause();
        const url = this.task.result?.form?.url.toString();

        if (!url || !this.file || !this.file.file) {
            this.options.onFailure(this.task, this.metaData);
            return;
        }

        const file = this.file.file;
        const fileExt = getImageExtension(this.file.file);

        const r = new Resumable({
            chunkRetryInterval: 3000,
            target: url.replace("api/upload", "api/resumable").replace(`server`, "s"),
            chunkSize: 2 * 1024 * 1024, // Chunk Size 2MB
            forceChunkSize: true,
            prioritizeFirstAndLastChunk: true,
            testChunks: false,
            generateUniqueIdentifier: () => {
                // this function is copied from fc-frontend
                let relativePath = file.webkitRelativePath || file.name;
                const size = file.size;
                const limit = 40;
                // We don't change anything if length is less than limit
                if (relativePath.length < limit) {
                    return (
                        size +
                        "-" +
                        Math.floor(Math.random() * 1000 + 5000) +
                        "-" +
                        relativePath.replace(/[^0-9a-zA-Z_-]/gim, "") +
                        "." +
                        fileExt
                    );
                } else {
                    relativePath = relativePath.replace(/[^0-9a-zA-Z_-]/gim, "");
                    return (
                        size +
                        "-" +
                        Math.floor(Math.random() * 1000 + 5000) +
                        "-" +
                        relativePath.substring(0, limit / 2) +
                        "-" +
                        relativePath.substring(relativePath.length - limit / 2) +
                        "." +
                        fileExt
                    );
                }
            },
        });

        this.r = r;

        let startTime = new Date();

        r.addFile(this.file.file);

        r.on("fileAdded", () => {
            r.upload();
        });

        r.on("fileError", () => {
            this.options.onFailure(this.task, this.metaData);
        });

        r.on("fileProgress", () => {
            const progress = Math.round(r.progress() * 100);
            const currentTime = new Date();
            const seconds = (currentTime.getTime() - startTime.getTime()) / 1000;

            this.options.onProgress(
                this.task,
                progress,
                calculateSpeed(seconds, progress, this.file.file.size),
                this.metaData,
            );
        });

        // @ts-ignore
        r.on("fileSuccess", (_file, event) => {
            const status = JSON.parse(event).fileUploadStatus;

            if (status === "done" || status === "partly_done") {
                r.on("complete", () => {
                    if (this.state !== "resumable") return;
                    this.handleJoin();
                });
            }
        });
    }

    public cancel() {
        if (!this.r) return;
        this.r.pause();
        this.r.cancel();
        this.r = null;
        // cancelling all the callbacks
        this.options = {
            onComplete: () => {},
            onFailure: () => {},
            onOfflineLimitReach: () => {},
            onProgress: () => {},
        };
    }

    private handleJoin() {
        this.state = "join";
        if (this.joinTryCount > MAX_RETRY_COUNT) {
            this.handleTaskFail(this.task);
            return;
        }
        this.joinTryCount++;
        console.log(`handleJoin trying for ${this.joinTryCount} time(s)`);
        const url = this.task.result?.form?.url.toString();
        if (!url || !this.r || !this.task.result?.form?.parameters.signature) return;

        const formData = new FormData();
        formData.append("identifier", this.getIdentifier());
        formData.append("signature", this.task.result.form?.parameters.signature ?? "");

        axiosInstance
            .post(url.replace("api/upload", "api/resumable/join").replace("server", "s"), formData)
            .then(() => {
                this.handleConfirm();
            })
            .catch(() => {
                setTimeout(() => {
                    console.log("retrying handleJoin");
                    this.handleJoin();
                }, 5000);
            });
    }

    private handleConfirm() {
        this.state = "confirm";
        if (this.confirmTryCount > MAX_RETRY_COUNT) {
            this.handleTaskFail(this.task);
            return;
        }
        this.confirmTryCount++;
        const url = this.task.result?.form?.url.toString();
        if (!url || !this.r || !this.task.result?.form?.parameters.signature) return;

        const newFormData = new FormData();
        newFormData.append("identifier", this.getIdentifier());
        newFormData.append("fileName", this.file.file.name);
        newFormData.append("signature", this.task.result.form?.parameters.signature ?? "");

        axiosInstance
            .post(url.replace("server", "s"), newFormData)
            .then(async () => {
                this.isAcknowledgementComplete = true;

                if (this.completedTask && !this.hasCompleted) {
                    this.hasCompleted = true;
                    this.options.onComplete(this.completedTask, this.metaData);
                }
            })
            .catch(() => {
                setTimeout(() => {
                    this.handleConfirm();
                }, 5000);
            });

        this.taskCompleteTimeout = setTimeout(() => {
            if (!this.hasCompleted) {
                gtag("event", `Upload Stuck`, {
                    event_category: window.location.pathname === "/" ? "Image Resizer" : window.location.pathname,
                    event_label: `Upload`,
                });
            }
        }, TEN_SECONDS);
    }

    // helper
    private getIdentifier() {
        if (!this.r) return "";

        return this.r.files
            .map((file) => {
                return file.uniqueIdentifier;
            })
            .join(",");
    }
}
