import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { MAX_RETRY_COUNT } from "client/config/configs";
import { FileData } from "client/types/FileData";
import { UploadSettings } from "client/types/HandleFile";
import { DEFAULT_TOAST_SHOW_TIME } from "components/common/Toast/ToastRenderer";
import { useToast } from "components/common/Toast/useToast";
import { useRef } from "react";
import { deleteImage } from "store/features/imageUploader/actions";
import { CreateImportTask } from "store/features/imageUploader/extraReducers/createImportTask";
import { ImageUpload, UploadType } from "store/features/imageUploader/types";
import { useAppDispatch } from "store/store";
import { translateFunc } from "./translateFunc";

export type HandleCreateImportTaskSuccess<Image> = (
    image: Image,
    task: Task,
    file: FileData,
    uplaodType: UploadType,
) => Promise<void>;

export type HandleCreateImportTask = <Image extends { id: string }>(
    image: Image,
    file: FileData,
    uploadType: UploadType,
    settings: UploadSettings[UploadType],
    createImportTask: CreateImportTask,
    handleCreateImportTaskSuccess: HandleCreateImportTaskSuccess<Image>,
) => Promise<void>;

export const useCreateImportTask = () => {
    const dispatch = useAppDispatch();
    const { toast } = useToast();
    const retryCountRef = useRef(0);

    const translateText = `${translateFunc("you-are-offline")} ${translateFunc("trying-to-reconnect")}`;
    const handleCreateImportTask: HandleCreateImportTask = async (
        image,
        file,
        uploadType,
        settings,
        createImportTask,
        handleCreateImportTaskSuccess,
    ) => {
        const result = await dispatch(
            createImportTask({
                imageId: image.id,
                type: uploadType,
                options: settings,
            }),
        );

        const payload = result.payload as PayloadAction<{ result: Task }>["payload"];

        if (result.meta.requestStatus === "rejected") {
            toast({
                closeable: true,
                position: "top-right",
                status: "error",
                text: translateText,
                time: DEFAULT_TOAST_SHOW_TIME,
            });
            setTimeout(() => {
                retryCreateImportTask(
                    image,
                    file,
                    uploadType,
                    settings,
                    createImportTask,
                    handleCreateImportTaskSuccess,
                );
            }, 5000);
        } else if (result.meta.requestStatus === "fulfilled") {
            handleCreateImportTaskSuccess(image, payload.result, file, uploadType);
        }
    };

    const retryCreateImportTask: HandleCreateImportTask = async (
        image,
        file,
        uploadType,
        settings,
        createImportTask,
        handleCreateImportTaskSuccess,
    ) => {
        if (retryCountRef.current >= MAX_RETRY_COUNT) {
            dispatch(deleteImage(image.id));
            return;
        }
        retryCountRef.current++;
        const result = await dispatch(
            createImportTask({
                imageId: image.id,
                type: uploadType,
                options: settings,
            }),
        );
        const payload = result.payload as PayloadAction<{ result: Task }>["payload"];
        if (result.meta.requestStatus === "fulfilled") {
            handleCreateImportTaskSuccess(image, payload.result, file, uploadType);
        } else {
            setTimeout(() => {
                retryCreateImportTask(
                    image,
                    file,
                    uploadType,
                    settings,
                    createImportTask,
                    handleCreateImportTaskSuccess,
                );
            }, 5000);
        }
    };

    return {
        handleCreateImportTask,
    };
};
